import React from 'react';
import SignUpForm from '../components/SignUpForm';
import HeroModule from '../components/HeroModule';
import Layout from '../components/Layout';
import Module from '../components/Module';
import SnakeModule from '../components/SnakeModule';
import CTA from '../components/CTA';
import SocialModule from '../components/SocialModule';
import VideoModule from '../components/VideoModule';

const IndexPage: React.FC<{}> = () => {
  return (
    <Layout
      pageTitle="Miter Meeting Tools: No More Bad Meetings!"
      description="Try out Miter today! Set goals and agendas. Take collaborative meeeting notes. Summarize decisions and tasks. Now for Zoom and Google Calendar."
    >
      <HeroModule
        imageName="home-hero"
        imageAlt="Screenshot of Miter in Zoom"
        background="Gradient"
        className="HomeHero"
      >
        <div className="HomeHeroContent">
          <h1>Run better meetings.</h1>
          <div className="HomeHeroBlurb BodyXLarge">
            Nine out of ten attendees agree: this meeting sucks. And why are there ten people here, anyway? Miter
            provides powerful tools and a simplified workflow to help you deliver productive meetings and clear
            outcomes, with less work.
          </div>
          <div className="ButtonBar">
            <CTA dest="WebApp" label="Try Miter Now" />
          </div>
        </div>
      </HeroModule>

      <VideoModule />

      <SocialModule />

      <SnakeModule
        layout="ImageRight"
        title="Less planning. Better plans."
        imageName="home-goal"
        imageAlt="Miter's pre-meeting planning screen with goal and topics"
      >
        <p>
          It's true: <strong>agendas don't make better meetings</strong>—at least not reliably. So, skip the "agenda
          theater" and plan better, faster. Miter focuses you on a{' '}
          <strong>specific, outcome-oriented, shared goal</strong> and a simple list of topics.
        </p>
        <p>Planning with Miter is so easy, you can do it in the first two minutes of the meeting!</p>
      </SnakeModule>

      <SnakeModule
        layout="ImageLeft"
        title="Outcomes or it didn't happen."
        imageName="home-notes"
        imageAlt="Miter&#39;s note-taking experience, showing marking a note as an action item"
      >
        <p>
          Does anybody actually <em>read</em> transcripts? (We asked: they don't.) Keep track of what <em>really</em>{' '}
          happened in your meeting with Miter.
        </p>
        <p>
          Synthesize together with <strong>lightweight, collaborative note-taking.</strong>
          <strong>Assign action items</strong> as you go with a simple @-mention.
          <strong>Collect outcomes with a single click,</strong> in real time: action items, decisions, starred notes.
        </p>
      </SnakeModule>

      <SnakeModule
        layout="ImageRight"
        title="Facilitation in a box."
        imageName="home-dynamics"
        imageAlt="The Brainstorm dynamic"
        anchorName="dynamics"
      >
        <p>Three people arguing while five watch--we've all been there. And we can do better.</p>
        <p>
          With Dynamics, you get <strong>active facilitation on demand:</strong> structures like Brainstorm, Prioritize,
          and Ask Everyone that guide the discussion toward its goal while you focus on participating.
        </p>
      </SnakeModule>

      <SnakeModule
        layout="ImageLeft"
        title="Concise, effortless summaries."
        imageName="home-summary"
        imageAlt="A Miter summary in Gmail"
      >
        <p>
          When the meeting ends, Miter gives give you a clean, concise summary. Not all the notes. Not a rehash of the
          agenda. Just what you need to answer the question, "What <em>really</em> happened in that meeting?"
        </p>
        <p>
          We automatically send the summary to everyone in the meeting. Or with just a few clicks, you can share it with
          anyone you like.
        </p>
      </SnakeModule>

      <SnakeModule
        layout="ImageRight"
        title="Action items that get done."
        imageName="home-tasks"
        imageAlt="A Miter summary in Gmail"
      >
        <p>After the meeting, where do your action items go? Are they lost? Are they buried in a doc somewhere?</p>
        <p>
          With Miter, action items have lives of their own--
          <strong>open the Miter dashboard to see everything assigned to you,</strong> and to everyone else too. So
          stuff can actually get done.
        </p>
        <p>
          And for recurring meetings, just click the <strong>Past Times</strong> button to see action items and
          decisions from last time.
        </p>
      </SnakeModule>

      <SnakeModule
        layout="ImageLeft"
        title="Our workflow in your workflow."
        imageName="home-platforms"
        imageAlt="Screenshots of Miter for web, GCal, and Zoom"
      >
        <p>
          Miter works where you are. Prep your meetings in Google Calendar with our
          <CTA dest="ChromeExtension" type="Link" label="Chrome extension;" />
          run them in Zoom using our
          <CTA dest="ZoomApp" type="Link" label="Zoom App;" />
          view <strong>summaries in Gmail;</strong> and find everything in our web app.
        </p>
      </SnakeModule>

      <Module container="BoxDark" title="Our Approach" layout="Columns">
        <div>
          <p>
            When we say "meeting," what do you feel? Boredom? Frustration? Panic? For most of us, meetings are a
            necessary evil, an inescapable distraction from actually
            <em>getting work done.</em>
          </p>
          <p>
            At Miter, we think that’s crazy. Meetings should be
            <em>part</em> of getting the work done. They can be energizing and constructive. With clear outcomes. And
            donuts. Or at least donut emojis.
          </p>
          <p>
            We've looked at the research. The difference between a bad meeting and a good one isn't complex agendas, or
            a timekeeper, or lengthy pre-reads. You can prep a great meeting in a minute or two. And you don't need to
            be a professional facilitator.
          </p>
        </div>
        <div>
          <p>
            But you <em>can</em> use a little help.
          </p>
          <p>
            Miter guides you to better meetings. We're rooted in organizational behavior and behavioral science, so we
            focus you on just the things you need to set your meeting up for success. We work in concert with the tools
            you already use, like Zoom and Google Calendar. We combine meeting science, choice architecture, and superb
            user experience to produce meeting magic.
          </p>
          <p>
            So people walk out of <em>your</em> meeting saying, “That was great!”
          </p>
        </div>
      </Module>

      <Module title="Get Involved" width="Narrow">
        <p>
          The best way to get involved is to
          <a href="https://app.miter.co" rel="noreferrer">
            <strong>take Miter for a spin,</strong>
          </a>
          try it in your meetings, and use our Feedback button liberally.
        </p>
        <p style={{marginBottom: 24}}>
          Not enough? Wanna get <em>more</em> involved? Become a Miter fan? Well, OK, if you say so. Drop us your email
          and we'll keep you informed.
        </p>
        <SignUpForm />
      </Module>
    </Layout>
  );
};

export default IndexPage;
