import classNames from 'classnames';
import React, {ReactNode, useCallback, useMemo, useState} from 'react';
import './Form.less';

const HubspotBaseUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.GATSBY_HUBSPOT_ACCOUNT}`;

interface FormProps {
  description: string;
  hubspotFormId: string;
  children: ReactNode;
  submitLabel: string | null;

  orient?: 'Row' | 'Col';
  identifier?: string; // wf-form-Mailing-List-Signup
  className?: string;
  successMessage?: string;
}

const FormTags = ['INPUT', 'TEXTAREA'];
type FormElement = HTMLInputElement | HTMLTextAreaElement;

type FormState = 'Active' | 'Processing' | 'Success' | 'Error';

const DefaultSuccessMessage = 'Thank you!';
const DefaultErrorMessage = 'An unknown error occurred. Sorry about that! Give it another shot.';
const DefaultFailedRequestMessage = 'We were unable to submit your request. Could be connection issues?';

const Form: React.FC<FormProps> = ({
  hubspotFormId,
  successMessage,
  description,
  identifier: idProp,
  className,
  children,
  submitLabel,
  orient,
}) => {
  const [formState, setFormState] = useState<[FormState, string?]>(['Active']);
  const identifier = useMemo(() => idProp || `form${Math.ceil(Math.random() * 10)}`, [idProp]);

  const submit = useCallback(
    async (form: HTMLFormElement) => {
      const elements = form.elements;
      const url = `${HubspotBaseUrl}/${hubspotFormId}`;
      const fields = [];

      for (let i = 0; i < elements.length; i++) {
        if (FormTags.includes(elements.item(i).tagName)) {
          const el = elements.item(i) as FormElement;
          fields.push({name: el.name, objectTypeId: 'contact', value: el.value});
        }
      }

      if (!fields.length) return setFormState(['Error', "Couldn't find any fields in this form!"]);

      try {
        const fetchResponse = await fetch(url, {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify({fields}),
        });

        const returnedJson = await fetchResponse.json();

        if (fetchResponse.status === 200) {
          setFormState(['Success', successMessage || DefaultSuccessMessage]);
        } else {
          setFormState(['Error', `Error: ${returnedJson.message || DefaultErrorMessage}`]);
        }
      } catch (err) {
        setFormState(['Error', DefaultFailedRequestMessage]);
      }
    },
    [hubspotFormId, successMessage]
  );

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = event => {
    submit(event.target as HTMLFormElement);
    setFormState(['Processing']);
    event.preventDefault();
  };

  const form = useMemo(() => {
    const [state] = formState;

    const buttonEl = submitLabel && (
      <button type="submit" disabled={state === 'Processing'}>
        {state === 'Processing' ? 'One Moment...' : submitLabel}
      </button>
    );

    if (state !== 'Success') {
      // Every state other than a successful submission contains the actual form
      return (
        <form className={orient} name={identifier} aria-label={description} onSubmit={handleSubmit}>
          {children}
          {buttonEl && (orient === 'Row' ? buttonEl : <div className="ButtonRow">{buttonEl}</div>)}
        </form>
      );
    } else return null;
  }, [formState, submitLabel, description, children, orient]);

  return (
    <div className={classNames('Form', formState[0], 'BodyMedium', className)}>
      {form}
      {formState[0] === 'Error' && <div className="Message">{formState[1] || DefaultErrorMessage}</div>}
      {formState[0] === 'Success' && <div className="Message">{formState[1] || DefaultSuccessMessage}</div>}
    </div>
  );
};

export default Form;
