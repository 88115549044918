import * as React from 'react';
import Form from './Form';

const SignUpForm: React.FC<{}> = () => (
  <Form
    hubspotFormId={process.env.GATSBY_SIGNUP_FORM_ID}
    orient="Row"
    submitLabel="Get Involved"
    description="Mailing list sign-up form"
  >
    <input
      type="email"
      name="email"
      data-name="email"
      className="TextField"
      placeholder="Your email address"
      maxLength={256}
      required
    />
  </Form>
);

export default SignUpForm;
